import React from "react";
// Stylesheet
import "./IndustriesWeServe.scss";
import Header from "../UI/Header";
import oil from "./../../Images/AboutUsPage/oil.svg";
import automotiveAndManufacturaing from "./../../Images/AboutUsPage/automotiveAndManufacturaing.svg";
import printingAndPacking from "./../../Images/AboutUsPage/printingAndPacking.svg";
import wireAndCable from "./../../Images/AboutUsPage/wireAndCable.svg";
import textileAndSpinnig from "./../../Images/AboutUsPage/textileAndSpinnig.svg";
import electricalandElectronics from "./../../Images/AboutUsPage/electricalandElectronics.svg";
import ignitionSystem from "./../../Images/AboutUsPage/ignitionSystem.svg";
import weldingAndCutting from "./../../Images/AboutUsPage/weldingAndCutting.svg";
import plumbing from "./../../Images/AboutUsPage/plumbing.svg";
import chemical from "./../../Images/AboutUsPage/chemical.svg";
import abrasivesAndGrinding from "./../../Images/AboutUsPage/abrasivesAndGrinding.svg";
import shotBlasting from "./../../Images/AboutUsPage/shotBlasting.svg";
import steelAndFoundry from "./../../Images/AboutUsPage/steelAndFoundry.svg";
import fluidControl from "./../../Images/AboutUsPage/fluidControl.svg";
import medical from "./../../Images/AboutUsPage/medical.svg";
import IndustryCard from "../UI/IndustryCard";
//Images
const IndustriesWeServe = () => {
  const industriesData = [
    { text: "Oil and Gas", icon: oil },
    { text: "Automotive and Manufacturing", icon: automotiveAndManufacturaing },
    { text: "Printing and Packaging", icon: printingAndPacking },
    { text: "Wire and Cable Industry", icon: wireAndCable },
    { text: "Textile and Spinning", icon: textileAndSpinnig },
    { text: "Electrical and Electronics", icon: electricalandElectronics },
    { text: "Ignition System", icon: ignitionSystem },
    { text: "Welding and Cutting", icon: weldingAndCutting },
    { text: "Plumbing and Faucets", icon: plumbing },
    { text: "Chemical and Pharmaceutical", icon: chemical },
    { text: "Abrasives and Grinding", icon: abrasivesAndGrinding },
    { text: "Shot Blasting", icon: shotBlasting },
    { text: "Steel and Foundry", icon: steelAndFoundry },
    { text: "Fluid Control", icon: fluidControl },
    { text: "Meical Devices", icon: medical },
  ];
  return (
    <section className="industriesWeServeSection">
      <Header text={"Industries We Serve"} />
      <p className="industriesPararaph">
        At NTB International Pvt Ltd, we serve a diverse range of industries,
        providing high-quality products and tailored solutions that meet their
        unique requirements. Our expertise extends to the following industries:
      </p>
      <div className="industriesGrid">
        {industriesData.map((industry, i) => (
          <IndustryCard key={i} icon={industry.icon} text={industry.text} />
        ))}
      </div>
    </section>
  );
};
export default IndustriesWeServe;
