import React, { useEffect } from "react";
// Stylesheet
import "./ContactUs.scss";
//Images
import PageImgSection from "../Components/Sections/PageImgSection";
import contactUs from "./../Images/contact-us.jpg";
import Header from "../Components/UI/Header";
import InquireButton from "../Components/UI/InquireButton";
const ContactUs = () => {
  const imgs = [{ img: contactUs, altText: "Contact Us" }];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="contactUs">
      <PageImgSection header={"Contact Us"} imgs={imgs} showStrip={false} />
      <section className="contactUsSection">
        <p className="contactUsText">
          Thank you for your interest in NTB International Pvt Ltd. If you have
          any inquiries, feedback, or would like to discuss potential
          partnerships, we are eager to hear from you.
        </p>
        <Header text={"Visit Us"} />
        <p className="contactUsText">
          We welcome visitors to our facilities by appointment. If you would
          like to schedule a visit, please contact our team in advance to
          arrange a suitable date and time.
        </p>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.515407166069!2d73.85255937494729!3d18.730501462728416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c9c4968d83ad%3A0x8d17dcaadd52fcff!2sNTB%20International%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1697537040485!5m2!1sen!2sin"
          className="mapStyles"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <p className="contactUsText">
          At NTB International, customer satisfaction is our top priority. Our
          dedicated team is ready to assist you with any questions, concerns, or
          requests you may have. We value your feedback and strive to provide
          exceptional service. Whether you are interested in our manufacturing
          capabilities, have a specific product inquiry, or need support, we are
          committed to delivering prompt and reliable assistance.
          <br />
          <br />
          We look forward to hearing from you and exploring how we can
          collaborate to meet your manufacturing needs. Reach out to us today!
        </p>
        <div className="contactUsInfoDiv">
          <div className="contactUsDiv">
            <table className="addressDiv">
              <tbody>
                <tr>
                  <td className="addressHeader">Address</td>
                  <td className="address">
                    NTB INTERNATIONAL PVT.LTD, 622/2 PUNE-NASHIK HIGHWAY KURULI,
                    PUNE 410 501.
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="contactSubDiv">
              <h2 className="contactSubHeader">
                {/* <img src={phoneIcon} className="headerIcons" alt="phone" /> */}
                Phone no:
              </h2>
              <table className="addressDiv">
                <tbody>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      Mobile:
                    </td>
                    <td className="address">9168684506</td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      Land Line:
                    </td>
                    <td className="address">02135662762/766</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="contactSubDiv">
              <h2 className="contactSubHeader">
                {/* <img src={mailIcon} className="headerIcons" alt="location" /> */}
                Email:
              </h2>

              <table className="addressDiv">
                <tbody>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      Ceramics:
                    </td>
                    <td className="address">ceramics@ntbindia.com</td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      ONG:
                    </td>
                    <td className="address">exports@ntbindia.com</td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      Belting:
                    </td>
                    <td className="address">belting@ntbindia.com</td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingLeft: "1rem" }}
                      className="addressHeader"
                    >
                      Inquiries:
                    </td>
                    <td className="address">enquiry@ntbindia.com</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <img className="contactUsInfoImg" src={contactUs} alt="contact-us" />
        </div>
        <InquireButton />
      </section>
    </main>
  );
};
export default ContactUs;
