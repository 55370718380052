import React from "react";
// Stylesheet
import "./WhoWeAreSection.scss";
import Header from "./../UI/Header";
import aboutUsImg from "./../../Images/AboutUsPage/about-us.png";
//Images
const WhoWeAreSection = () => {
  return (
    <section className="whoWeAreSection">
      <Header text={"Who we are?"} />
      <p className="whoWeAreText">
        NTB Internationals ceramics was established in 1989 by Kamal Kacholia. A
        Division of NTB International Pvt Limited Located at Chakan, near Pune.
        <br />
        <br />
        7500 sq. mtr manufacturing area.
        <br /> 2000 sq. mtr office area 60 staff and 250 workmen
        <br />
        <br /> NTB Hitech Ceramics is an ISO 9001:2015 Company certified by DNV
        - Netherlands.
        <br />
        <br /> We take pride in being a leading manufacturer of Oxide and
        Non-oxide Ceramics for Structural, Ballistic, Wear and Rotational
        Leak-tight, Corrosion, Heat Resistant and Electrical Insulation
        applications.
      </p>
      <img src={aboutUsImg} className="aboutUsImg" alt="about-us" />
    </section>
  );
};
export default WhoWeAreSection;
