import React, { useEffect } from "react";

import PageImgSection from "../Components/Sections/PageImgSection";
import WhatWeDoSection from "../Components/Sections/WhatWeDoSection";
import DirectorSection from "../Components/Sections/DirectorSection";
import BigTextSection from "../Components/Sections/BigTextSection";
import Container from "../Components/Container";
// import TestimonialSection from "../Components/Sections/TestimonialSection";

import IndustrialSealFaces from "./../Images/Carousel/Industrial_seal_faces.png";
import belting from "./../Images/Carousel/belting.jpg";
import choke from "./../Images/Carousel/choke.png";
import silica from "./../Images/Carousel/silica.png";

import map from "./../Images/map.svg";

import { motion } from "framer-motion";

const Homepage = () => {
  const imgs = [
    { img: IndustrialSealFaces, altText: "Industrial_seal_faces" },
    { img: choke, altText: "choke" },
    { img: silica, altText: "silica" },
    { img: belting, altText: "belting" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <PageImgSection imgs={imgs} hero={true} showStrip={false} />
      <WhatWeDoSection />
      <div>
        <DirectorSection />
        <motion.img
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          src={map}
          className="map"
          alt="map"
          width={"100%"}
        />
      </div>
      <BigTextSection />
      {/* <TestimonialSection /> */}
    </Container>
  );
};
export default Homepage;
