import React from "react";
// Stylesheet
import "./Footer.scss";
import Header from "./UI/Header";

import { Link } from "react-router-dom";
// import facebook from "./../Images/facebook.svg";
import linkedIn from "./../Images/linkedin.svg";
// import tweeter from "./../Images/tweeter.svg";
// import instagram from "./../Images/instagram.svg";
import whatsapp from "./../Images/whatsapp.svg";
import { links } from "./../Data/data";

//Images
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="companyDetails">
          <div className="aboutUsDiv">
            <Header text={"About Us"} small={true} />
            <p className="componyInfo">
              At NTB International Pvt Ltd, we take pride in being a leading
              manufacture of Oxide and Non-oxide Ceramics for Structural,
              Ballistic, Wear and Rotational Leak-tight, Corrosion, Heat
              Resistant and Electrical Insulation applications.
            </p>
          </div>
          <div className="companyLinkDiv">
            <Header text={"Company Links"} small={true} />
            <div className="linkDiv">
              <div>
                {links.map((link, i) => (
                  <Link key={i} className="footerLink" to={link.navigate}>
                    {link.text}
                  </Link>
                ))}
              </div>
              {/* <div>
                <Link className="footerLink" to="/">
                  Contact Us
                </Link>
                <Link className="footerLink" to="/">
                  Resources
                </Link>
              </div> */}
            </div>
          </div>
          <div className="contactUsDiv">
            <Header text={"Contact Info"} small={true} />
            <div className="addressDiv">
              <h2 className="addressHeader">Address:</h2>
              <p className="address">
                NTB INTERNATIONAL PVT.LTD, 622/2 PUNE-NASHIK HIGHWAY KURULI,
                PUNE 410 501.
              </p>
            </div>
            <div className="contactSubDiv">
              <h2 className="contactSubHeader">Phone no:</h2>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">Main:</h3>
                <p className="contactInfo">9168684506</p>
              </div>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">Land Line:</h3>
                <p className="contactInfo">02135662762/766</p>
              </div>
            </div>
            <div className="contactSubDiv">
              <h2 className="contactSubHeader">Email:</h2>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">Ceramics:</h3>
                <p className="contactInfo">ceramics@ntbindia.com</p>
              </div>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">ONG:</h3>
                <p className="contactInfo">exports@ntbindia.com</p>
              </div>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">Belting:</h3>
                <p className="contactInfo">belting@ntbindia.com</p>
              </div>
              <div className="contactInfoDiv">
                <h3 className="contactInfoSubHeader">Inquiries:</h3>
                <p className="contactInfo">enquiry@ntbindia.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="subFooter">
          <p className="copyright">© NTB Hitech Ceramics {currentYear}</p>
          <div className="socialMeidaDiv">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://wa.me/message/J3PQEQGAIPRYN1"
            >
              <img className="socialMediaIcons" src={whatsapp} alt="whatsapp" />
            </a>
            {/* <a href="">
              <img className="socialMediaIcons" src={facebook} alt="" />
            </a> */}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/ntb-hitech-ceramics/"
            >
              <img className="socialMediaIcons" src={linkedIn} alt="linkedin" />
            </a>
            {/* <a href="">
              <img className="socialMediaIcons" src={tweeter} alt="" />
            </a>
            <a href="">
              <img className="socialMediaIcons" src={instagram} alt="" />
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
