import React from "react";
// Stylesheet
import "./CompanyOverViewSection.scss";
import Header from "../UI/Header";
import OverView from "../UI/OverView";
import { overViewData } from "../../Data/data";
//Images
const CompanyOverViewSection = () => {
  return (
    <section className="companyOverViewSection">
      <div className="companyOverViewContent">
        <Header text={"Company Overview"} />
        <div className="companyOverView">
          {overViewData.map((data, i) => (
            <OverView
              key={i}
              icon={data.icon}
              header={data.header}
              paragraph={data.paragraph}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
export default CompanyOverViewSection;
