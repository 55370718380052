import React from "react";
// Stylesheet
import "./MatrixCard.scss";
//Images
const MatrixCard = ({ icon, header, text }) => {
  return (
    <div className="matrixCard">
      <img src={icon} alt={text} className="matrixIcon" />
      <h2 className="matrixHeader">
        {header}
        <span>+</span>
      </h2>
      <p className="matrixSubHeader">{text}</p>
    </div>
  );
};
export default MatrixCard;
