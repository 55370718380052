import React from "react";
// Stylesheet
import "./OverView.scss";
//Images
const OverView = ({ icon, header, paragraph }) => {
  return (
    <div className="overView">
      <img src={icon} className="overViewIcon" alt="our-mission" />
      <div className="overViewText">
        <h2 className="overViewHeader">{header}</h2>
        <p className="overViewPara">{paragraph}</p>
      </div>
    </div>
  );
};
export default OverView;
