import React, { useEffect } from "react";
// Stylesheet
import productData from "./../Data/product-data.json";
import "./Product.scss";
import Header from "../Components/UI/Header";
import InquireButton from "../Components/UI/InquireButton";
import { Link, useParams } from "react-router-dom";
//Images
const Product = () => {
  const { id } = useParams();
  const allProducts = productData.flatMap((entry) => entry.products);
  const [product] = allProducts.filter((data) => data.id === id);
  // const description = product.description
  //   .split("<%break%>")
  //   .map((sentence) => sentence.trim())
  //   .join("\n\n");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="product">
      <div className="productDetails">
        <h2 className="currentProductDetail">
          <Link to={"/products"}>Products</Link> -&gt;{" "}
          <Link to={"/products"}>{product.category}</Link> -&gt;{" "}
          <span>{product.name}</span>
        </h2>
        <div className="productSubDiv">
          <img
            className="productImage"
            src={product.coverImg}
            alt={product.name}
          />
          <div className="productTextContent">
            <Header text={product.name} />
            <p className="productDescription">{product.description}</p>
            {product.subDiv.map((data, i) => (
              <div key={i} className="productSubDiv">
                <h2 className="productSubHeader">{data.header}</h2>
                <p className="productSubPara">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="tableMainDiv">
          {product.table.map((data, i) => (
            <div key={i} className="tableDiv">
              <h2 className="tableHeaderHeader">{data.tableHeader}</h2>
              <img
                className="tableImage"
                src={data.tableImg}
                alt={data.tableHeader}
              />
            </div>
          ))}
        </div>
        <div className="backDiv">
          <Link to={"/products"}>Back to Products</Link>
          <InquireButton />
        </div>
      </div>
    </main>
  );
};
export default Product;
