import React from "react";
// Stylesheet
import "./IndustryCard.scss";
//Images
const IndustryCard = ({ icon, text }) => {
  return (
    <div className="industryCard">
      <img src={icon} className="industryIcon" alt="text" />
      <h2 className="industryHeader">{text}</h2>
    </div>
  );
};
export default IndustryCard;
