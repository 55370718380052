import React from "react";
import "./Timeline.scss";
import timeline from "./../../Images/time.svg";
import Header from "../UI/Header";
//Images
const Timeline = () => {
  return (
    <section className="timeline">
      <Header text={"Company Timeline"} />
      <img src={timeline} alt="timeline" className="timelineImg" />
    </section>
  );
};
export default Timeline;
