import React from "react";
import InquireIcon from "./../../Images/inquire.png";
import "./InquireButton.scss";

const InquireButton = () => {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      href="https://wa.me/message/J3PQEQGAIPRYN1"
    >
      <div className="inquireButton">
        <p className="inquireText">Inquire Now</p>
        <img src={InquireIcon} alt="inquire" className="inquireIcon" />
      </div>
    </a>
  );
};

export default InquireButton;
