import React, { useRef } from "react";

import "./MeetTheTeamSection.scss";
import Header from "../UI/Header";
import TeamCard from "../UI/TeamCard";

import emp1 from "./../../Images/AboutUsPage/Anil Raskar - Maintenance HOD.jpeg";
// import emp2 from "./../../Images/AboutUsPage/Bhausaheb Kanhurkar - Safety Officer.jpeg";
import emp3 from "./../../Images/AboutUsPage/Jehangir Shaikh - Marketing HOD.jpeg";
import emp4 from "./../../Images/AboutUsPage/Kishanlal Verma - Finance HOD.jpeg";
import emp5 from "./../../Images/AboutUsPage/Laxmi Jagadale - Quality HOD.jpeg";
import emp6 from "./../../Images/AboutUsPage/Manju Kacholia - Director.jpeg";
import emp7 from "./../../Images/AboutUsPage/N. R. Walve - Production HOD.jpeg";
import emp8 from "./../../Images/AboutUsPage/Raja Palepu - CEO.jpeg";
import emp9 from "./../../Images/AboutUsPage/Raju Datir - Purchase HOD.jpeg";
import emp10 from "./../../Images/AboutUsPage/Siddharam Chadachane - HR & Admin Head.jpeg";
import emp11 from "./../../Images/AboutUsPage/Aditya Pachisia_page.jpg";
import arrow from "./../../Images/AboutUsPage/arrow.png";
import managerImg from "./../../Images/manager.svg";

const MeetTheTeamSection = () => {
  const teamDivRef = useRef(null);

  const teamsData = [
    {
      img: managerImg,
      teamName: "Shri Kamal Kacholia",
      designation: "Managing Director",
    },

    { img: emp6, teamName: "Manju Kacholia", designation: "Director" },
    { img: emp8, teamName: "Raja Palepu", designation: "CEO" },
    { img: emp4, teamName: "Kishanlal Verma", designation: "Finance HOD" },
    {
      img: emp11,
      teamName: "Aditya Pachisia",
      designation: "Marketing Manager",
    },
    { img: emp3, teamName: "J.B. Shaikh", designation: "Development Manager" },
    { img: emp1, teamName: "Anil Raskar", designation: "Maintenance HOD" },
    { img: emp5, teamName: "Laxmi Jagadale", designation: "Quality HOD" },
    { img: emp7, teamName: "N. R. Walve", designation: "Production HOD" },
    { img: emp9, teamName: "Raju Datir", designation: "Purchase HOD" },
    // {
    //   img: emp2,
    //   teamName: "Bhausaheb Kanhurkar",
    //   designation: "Safety Officer",
    // },
    {
      img: emp10,
      teamName: "Siddharam Chadachane",
      designation: "HR & Admin Head",
    },
  ];

  const scrollLeft = () => {
    teamDivRef.current.scrollBy({
      left: -300, // adjust scroll distance as needed
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    teamDivRef.current.scrollBy({
      left: 300, // adjust scroll distance as needed
      behavior: "smooth",
    });
  };
  return (
    <section className="meetTheTeamSection">
      <div className="meetTheTeamContent">
        <Header text="Meet the team" />
        <div className="meetTheTeamSliderDiv">
          <button className="buttonSlider leftSlider" onClick={scrollRight}>
            <img
              src={arrow}
              alt="arrow"
              className="leftArrow arrowSliderImage"
            />
          </button>
          <button className="buttonSlider rightSlider" onClick={scrollLeft}>
            <img
              src={arrow}
              alt="arrow"
              className="rightArrow arrowSliderImage"
            />
          </button>
          <div className="teamDiv" ref={teamDivRef}>
            {teamsData.map((team, i) => (
              <TeamCard
                key={i}
                img={team.img}
                teamName={team.teamName}
                designation={team.designation}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default MeetTheTeamSection;

// import React, { useState } from "react";
// import "./MeetTheTeamSection.scss"; // Make sure your CSS file is correctly imported
// import Header from "../UI/Header";
// import TeamCard from "../UI/TeamCard";
// import { teamsData } from "../../Data/data";

// const MeetTheTeamSection = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const cardsToShow = 4;
//   const cardWidth = 250; // Update with your card width
//   const maxIndex = Math.max(0, teamsData.length - cardsToShow);

//   const showNextTeams = () => {
//     if (currentIndex < maxIndex) {
//       setCurrentIndex(currentIndex + 1);
//     }
//   };

//   const showPrevTeams = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex(currentIndex - 1);
//     }
//   };

//   return (
//     <section className="meetTheTeamSection">
//       <div className="meetTheTeamContent">
//         <Header text="Meet the team" />
//         <div className="teamGallery">
//           {" "}
//           {/* Use className instead of class */}
//           <div className="teamDiv">
//             {" "}
//             {/* Use className instead of class */}
//             {teamsData
//               .slice(currentIndex, currentIndex + cardsToShow)
//               .map((team, i) => (
//                 <TeamCard
//                   key={i}
//                   img={team.img}
//                   teamName={team.teamName}
//                   designation={team.designation}
//                 />
//               ))}
//           </div>
//         </div>
//         <button className="prevBtn" onClick={showPrevTeams}>
//           Previous
//         </button>
//         <button className="nextBtn" onClick={showNextTeams}>
//           Next
//         </button>
//       </div>
//     </section>
//   );
// };

// export default MeetTheTeamSection;
