import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import productData from "./../Data/product-data.json";

// Stylesheet
import "./NavigationBar.scss";
//Images
import logo from "./../Images/logo.png";
import hamburger from "./../Images/hamburger-menu.svg";
import close from "./../Images/close.svg";
// import navArrow from "./../Images/navArrow.svg";

import { links } from "../Data/data";

const NavigationBar = () => {
  const [navigation, setNavigation] = useState(false);
  const [subNavigation, setSubNavigation] = useState(false);
  const navigate = useNavigate();
  const navigationHandler = () => {
    setNavigation((prev) => !prev);
  };

  const navigateHandler = (e, id) => {
    e.stopPropagation();
    const route = `/product/${id}`;
    navigate(route);
  };

  return (
    <nav className="navigationBar">
      <img src={logo} alt="ntb-international-pvt-ltd" className="logo" />
      <ul className={`navLinks ${navigation && "navLinksActive"}`}>
        {links.map((link, i) => (
          <li
            onClick={() => {
              setNavigation(false);
            }}
            key={i}
            className="navLink"
          >
            <NavLink
              onMouseEnter={() => {
                if (link.text === "Products") {
                  setSubNavigation(true);
                }
              }}
              className={({ isActive }) => (isActive ? "linkActive" : "")}
              to={link.navigate}
            >
              {link.text}
              {/* {link.text === "Products" && (
                <>
                  {subNavigation && (
                    <ul
                      onMouseLeave={() => {
                        if (link.text === "Products") {
                          setSubNavigation(false);
                        }
                      }}
                      className="productDropdown"
                    >
                      {productData.map((data, index) => (
                        <li key={index} className="productSubLink">
                          <p key={data.id}>{data.category}</p>
                          <img
                            alt="nav-arrow"
                            className="navArrow"
                            src={navArrow}
                          ></img>
                          <ul className="productSingleLinks">
                            {data.products.map((links, i) => (
                              <li
                                onClick={(e) => {
                                  navigateHandler(e, links.id);
                                }}
                                key={i}
                                className="productSingleLink"
                              >
                                {links.name}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )} */}
            </NavLink>
          </li>
        ))}

        <img
          onClick={navigationHandler}
          src={close}
          className="close"
          alt="X"
        />
      </ul>
      <img
        className="hamburger"
        onClick={navigationHandler}
        src={hamburger}
        alt="menu"
      />
    </nav>
  );
};
export default NavigationBar;
