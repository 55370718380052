import React, { useContext } from "react";
// Stylesheet
import "./SubDivisionSection.scss";
import Header from "../UI/Header";
import PrimaryBtn from "../UI/PrimaryBtn";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../store/product-store";
//Images
const SubDivisionSection = ({
  header,
  paragraph,
  img,
  reverse,
  filterValue,
}) => {
  const { filterHandler } = useContext(ProductContext);

  const navigate = useNavigate();
  const buttonHandler = () => {
    filterHandler(filterValue);
    const route = `/products`;

    navigate(route);
  };

  return (
    <section
      className={`subDivisionSection ${reverse && "subDivisionSectionWhite"}`}
    >
      <div
        className={`subDivisionContent ${
          reverse && "subDivisionContentReverse"
        }`}
      >
        <div className="textContent">
          <Header text={header} />
          <p className="textContentPara">{paragraph}</p>
          <PrimaryBtn buttonHandler={buttonHandler} text={"See Products"} />
        </div>
        <div className="subContentImgDiv">
          <img className="subImg" src={img} alt="img" />
        </div>
      </div>
    </section>
  );
};
export default SubDivisionSection;
