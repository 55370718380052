import { createContext, useReducer } from "react";
import productData from "./../Data/product-data.json";

export const ProductContext = createContext({
  products: [],
  filterHandler: () => {},
  searchHandler: () => {},
});

const allProducts = productData.flatMap((entry) => entry.products);

const reduceFunc = (state, action) => {
  if (action.type === "FILTER") {
    if (action.payload !== "Alphabet") {
      const filteredProduct = allProducts.filter(
        (product) => product.category === action.payload
      );
      return { products: filteredProduct };
    } else {
      const sortedData = allProducts
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      return { products: sortedData };
    }
  } else if (action.type === "SEARCH") {
    const filteredData = allProducts.filter((propduct) =>
      propduct.name.toLowerCase().includes(action.payload.toLowerCase())
    );
    return { products: filteredData };
  }
  return state;
};

const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduceFunc, { products: allProducts });

  const filterHandler = (value) => {
    dispatch({ type: "FILTER", payload: value });
  };

  const searchHandler = (value) => {
    dispatch({ type: "SEARCH", payload: value });
  };

  const value = {
    products: state.products,
    filterHandler: filterHandler,
    searchHandler: searchHandler,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export default ProductProvider;
