import React from "react";
// Stylesheet
import "./PrimaryBtn.scss";
//Images
const PrimaryBtn = ({ text, large, buttonHandler }) => {
  return (
    <button
      onClick={buttonHandler}
      className={`primaryBtn ${large && "primaryBtnLarge"}`}
    >
      {text}
    </button>
  );
};
export default PrimaryBtn;
