import React from "react";
// Stylesheet
import "./TeamCard.scss";
//Images
const TeamCard = ({ img, teamName, designation }) => {
  return (
    <div className="teamCard">
      <img src={img} alt={teamName} className="teamImg" />
      <h2 className="teamName">{teamName}</h2>
      <p className="teamDesignation">{designation}</p>
    </div>
  );
};
export default TeamCard;
