import React, { useContext, useEffect } from "react";
import "./ProductsPage.scss";

import PageImgSection from "../Components/Sections/PageImgSection";
import SearchBar from "../Components/UI/SearchBar";
import ProductCard from "../Components/UI/ProductCard";
import fau from "./../Images/Carousel/fau.jpg";
import Container from "../Components/Container";
import mechanicalSealFace1 from "./../Images/Carousel/mechanicalSealFaces2.jpg";
import mechanicalSealFace2 from "./../Images/Carousel/mechanicalSealFaces3.jpg";
import InquireButton from "../Components/UI/InquireButton";
import { ProductContext } from "../store/product-store";

const ProductsPage = () => {
  const { products } = useContext(ProductContext);
  const imgs = [
    { img: fau, altText: "product-1" },
    { img: mechanicalSealFace1, altText: "product-2" },
    { img: mechanicalSealFace2, altText: "product-3" },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <PageImgSection header={"Products"} imgs={imgs} />
      <SearchBar />
      {products.length ? (
        <div className="productGrid">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              name={product.name}
              img={product.coverImg}
            />
          ))}
        </div>
      ) : (
        <p className="noProductFound">No products found</p>
      )}
      <div className="productBackDiv">
        <InquireButton />
      </div>
    </Container>
  );
};
export default ProductsPage;

// {
//   "id": "5mxsh46k47ho2c3",
//   "subDiv": [],
//   "name": "Positive Choke Carbide Tubes",
//   "category": "GasAndOil",
//   "coverImg": "/Images/Products/oilAndGas/",
//   "img": [],
//   "table": [],

//   "description": "NTB Positive Chokes - NTB Positive Chokes accept replaceable fixed orifice flow beans (Ceramic or Carbide lined for severe applications) that are measured in 64th. of an inch. All Adjustable Chokes can be converted into Positive Chokes by replacing the bonnet assembly with an appropriate blanking plug and choke bean assembly. Positive Choke beans range from 4/64” to 48/64” for ¾” beans and from 50/64” to 64/64” for the 1” Positive Chokes. NTB positive bean chokes are manufactured with SS410 or Other Steels (see above chart) and are brazed with highly wear resistant Tungsten Carbide Cores with suitable orifices."
// },
// {
//   "id": "i4ab9d2y3oxw7ac",
//   "subDiv": [],
//   "name": "NTB Gate Valves",
//   "category": "GasAndOil",
//   "coverImg": "/Images/Products/oilAndGas/dummy.jpg",
//   "img": [],
//   "table": [],

//   "description": "Gate valves are primarily designed to start or stop a flow when straight line flow of fluid with minimum flow restriction is needed. In usage these valves are kept either fully opened or fully closed. NTB can offer the various components of Gate Valves."
// },
