import React, { useEffect } from "react";
import PageImgSection from "../Components/Sections/PageImgSection";
import WhoWeAreSection from "../Components/Sections/WhoWeAreSection";
import Timeline from "../Components/Sections/Timeline";
import CompanyOverViewSection from "../Components/Sections/CompanyOverViewSection";
import IndustriesWeServe from "../Components/Sections/IndustriesWeServe";
import MeetTheTeamSection from "../Components/Sections/MeetTheTeamSection";
import aboutUsImg from "./../Images/AboutUsPage/about-us.png";
import Container from "../Components/Container";

const AboutUs = () => {
  const imgs = [{ img: aboutUsImg, altText: "about-us" }];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <PageImgSection header={"About Us"} imgs={imgs} />
      <WhoWeAreSection />
      <CompanyOverViewSection />

      <IndustriesWeServe />
      <Timeline />
      <MeetTheTeamSection />
    </Container>
  );
};
export default AboutUs;
