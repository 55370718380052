import React from "react";
import "./PageImgSection.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PrimaryBtn from "../UI/PrimaryBtn";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

const PageImgSection = ({ header, imgs, hero = false, showStrip = true }) => {
  const navigate = useNavigate();
  const buttonHandler = () => {
    const route = `/products`;
    navigate(route);
  };

  return (
    <section className={`pageImgSection ${hero && "heroCarousel"}`}>
      {hero ? (
        <div className="headerCarouselContentDiv">
          <h1 className="carouselHeader">
            Innovative Ceramic Solutions by NTB Hitech Ceramics
          </h1>
          <p className="carouselSubHeader">
            Transforming industries with advanced ceramics for over 30 years.
            Discover reliability, quality, and sustainability at NTB Hitech
            Ceramics.
          </p>
          <PrimaryBtn
            buttonHandler={buttonHandler}
            text={"Explore"}
            large={true}
          />
        </div>
      ) : (
        <h2 className="pageImgHeader">{header}</h2>
      )}
      <div className="overlay" />

      {showStrip ? <div className="overlay-bottom" /> : null}

      <Carousel
        showIndicators={imgs.length > 1 ? true : false}
        interval={4000}
        showArrows={false}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        stopOnHover={false}
      >
        {imgs.map((product, i) => (
          <img
            key={i}
            className={`carouselImg ${hero && "heroCarousel"}`}
            alt={product.altText}
            src={product.img}
          />
        ))}
      </Carousel>
    </section>
  );
};
export default PageImgSection;
