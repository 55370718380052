import React from "react";
import "./WhatWeDoSection.scss";
import Header from "../UI/Header";
import DivisionCard from "../UI/DivisionCard";
import MatrixCard from "../UI/MatrixCard";
import { motion } from "framer-motion";
import belting from "./../../Images/Carousel/belting.jpg";
import IndustrialSealFaces from "./../../Images/Carousel/Industrial_seal_faces.png";
import choke from "./../../Images/Carousel/choke.png";

import icon1 from "./../../Images/icon1.svg";
import icon2 from "./../../Images/icon2.svg";
import icon3 from "./../../Images/icon3.svg";

const cards = [
  {
    img: IndustrialSealFaces,
    header: "Ceramics Division",
    text: "Our Ceramics Division specializes in the manufacturing and distribution of high-quality ceramic products.",
  },
  {
    img: choke,
    header: "Oil and Gas Division",
    text: "We, NTB International Pvt. Ltd, are manufacturers of Choke Valve Products related to Oil and Gas Industry. We have a state of the art manufacturing facility near the Industrial city of Pune, Maharashtra, India We can offer various Choke Valve Products like Seats ¾’, 1” and 2', Stems ¾’, 1” and 2, Choke Beans 4/64” to 64/64”, Gate valve parts and TC Plugs &Cages. We can also offer Steel and Tungsten Carbide parts for the above products, which can be assembled at your end. All above products offer by us are manufactured as per NACE Standards are exported to US and Canada markets in large quantities. Our prices will be competitive and products will be of good quality and are made as per Oil and Gas Industry Standards.",
  },
  {
    img: belting,
    header: "Belting Division",
    text: "With over 6000+ satisfied customers for last 30 years, NTB has successfully moved the Indian Industry with its diversified range of products to almost all Industrial Segments. Our International Collaborations help us to deliver Quality material produced with Global standards. Added to same Personalized service of Technical Advice, with a Technical & Sales staff that would ensure trouble free performance to end customers.",
  },
];

const matrix = [
  { icon: icon1, header: "1434", text: "Project Completed" },
  { icon: icon2, header: "2384", text: "Successful Delivery" },
  { icon: icon3, header: "934", text: "Product Sales Increase" },
];

const WhatWeDoSection = React.memo(() => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className="whatWeDoSection"
    >
      <Header text={"What we do?"} />
      <p className="paragraph">
        At NTB International Pvt Ltd, we take pride in being a leading
        manufacturer of Oxide and Non-oxide Ceramics for various applications.
        NTB Hitech Ceramics is an ISO 9001:2015 certified company.
      </p>
      <div className="cardDiv">
        {cards.map((card, i) => (
          <DivisionCard
            key={i}
            img={card.img}
            header={card.header}
            text={card.text}
          />
        ))}
      </div>
      <div className="matrixDiv">
        {matrix.map((data, i) => (
          <MatrixCard
            key={i}
            icon={data.icon}
            header={data.header}
            text={data.text}
          />
        ))}
      </div>
    </motion.section>
  );
});

export default WhatWeDoSection;
