import React from "react";
// Stylesheet
import "./ProductCard.scss";
// import demoProduct from "./../../Images/Products/fau.jpg";
import { Link } from "react-router-dom";
//Images
const ProductCard = ({ id, name, img }) => {
  return (
    <div className="productCard">
      <img src={img} alt={name} className="productImg" />
      <div className="productContentDiv">
        <h2 className="productName">{name}</h2>
        <Link to={`/products/${id}`} className="productLink">
          See Product
        </Link>
      </div>
    </div>
  );
};
export default ProductCard;
