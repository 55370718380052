import React from "react";
import "./DirectorSection.scss";
import managerImg from "./../../Images/manager.svg";
import Header from "../UI/Header";
import { motion } from "framer-motion";

const DirectorSection = React.memo(() => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="directorSection"
    >
      <div className="directorSectionContentDiv">
        <img src={managerImg} alt="" className="managerImg" loading="lazy" />
        <div>
          <Header text={"Foreword by Managing Director"} />
          <p className="directorText">
            My journey began in the USA, where I earned engineering and business
            degrees and had the privilege of working on research projects for
            NASA, the US Army, and Navy. In 1977, I returned to India and,
            together with my younger brother, embarked on a venture to
            revolutionize the power transmission industry by manufacturing
            advanced flat belts, in collaboration with L.S. Shingle Inc. USA.
            These belts replaced traditional leather belts that my father had
            traded in.
            <br />
            <br />
            In 1988, our path led us into the world of technical ceramics. Since
            then, our journey has been defined by passion, dedication, and an
            unwavering pursuit of excellence. In 1995, we expanded our horizons
            to include the manufacturing of critical components for the Oil and
            Gas exploration sector.
            <br />
            <br />
            Over the past 35 years, NTB has become synonymous with innovation
            and precision in ceramics and Oil and Gas component manufacturing.
            We've consistently pushed the boundaries of what's possible, serving
            industries in India and around the world. At NTB, our team of
            experts works closely with clients, partnering with them to provide
            tailored solutions that address their unique requirements and
            challenges. We're not just manufacturers; we're collaborators on a
            shared journey of innovation, growth, and success.
            <br />
            <br />
            Thank you for considering NTB International Pvt Ltd as your partner.
            We look forward to sharing this journey with you.
          </p>
          <br />
          <p className="directorDetails">-Shri Kamal Kacholia</p>
          <p className="directorDetails">Managing Director</p>
        </div>
      </div>
    </motion.section>
  );
});

export default DirectorSection;
