import React, { useEffect } from "react";
// Stylesheet
import "./DivisionPage.scss";
import PageImgSection from "../Components/Sections/PageImgSection";
import DivisionSection from "../Components/Sections/DivisionSection";
import SubDivisionSection from "../Components/Sections/SubDivisionSection";
import silica from "./../Images/Carousel/silica.png";
import IndustrialSealFaces from "./../Images/Carousel/Industrial_seal_faces.png";
import belting from "./../Images/Carousel/belting.jpg";
import choke from "./../Images/Carousel/choke.png";

//Images
const DivisionPage = () => {
  const imgs = [
    { img: IndustrialSealFaces, altText: "Industrial_seal_faces" },
    { img: choke, altText: "choke" },
    { img: silica, altText: "silica" },
    { img: belting, altText: "belting" },
  ];
  const divisionsData = [
    {
      header: "Ceramics Division:",
      paragraph:
        "Our Ceramics Division specializes in the manufacturing and distribution of high-quality ceramic products. With advanced production techniques and a deep understanding of ceramic materials, we offer a comprehensive range of ceramic components for various applications. From industrial ceramics to precision components, our Ceramics Division delivers solutions that excel in wear, durability, heat resistance, and performance.",
      img: IndustrialSealFaces,
      reverse: true,
      filterValue: "Ceramics",
    },
    {
      header: "Oil And Gas Division:",
      paragraph:
        "We, NTB International Pvt. Ltd, are manufacturers of Choke Valve Products related to Oil and Gas Industry. We have a state of the art manufacturing facility near the Industrial city of Pune, Maharashtra, India We can offer various Choke Valve Products like Seats ¾’, 1” and 2', Stems ¾’, 1” and 2, Choke Beans 4/64” to 64/64”, Gate valve parts and TC Plugs &Cages. We can also offer Steel and Tungsten Carbide parts for the above products, which can be assembled at your end. All above products offer by us are manufactured as per NACE Standards are exported to US and Canada markets in large quantities. Our prices will be competitive and products will be of good quality and are made as per Oil and Gas Industry Standards.",
      img: choke,
      reverse: false,
      filterValue: "GasAndOil",
    },
    {
      header: "Conveyor Systems and Belting Division:",
      paragraph:
        "NTB is a market leading manufacturere and exporter of all types of indoor conveyor systems.company is dedicated to continuously developing ans improving it's products and services. we have expertise and experience to satisfy customer's needs in today's scenerion. NTB is proud of it's strong customer's relationship with prime objective of helping customers becoming more efficient and reduce cost. NTB conveyor helps save money by increasing productivity and keeping operating costs low while improving operation safety. Reduce engineering time by offering designs that fit virtually every application type. We manufacture reliable Industrial Equipment for Material Handling and Specialized Conveyors conforming to International Standards. The Activities includes Design, Engineering, Manufacture, Testing, Supply, Erection and Commissioning.",
      img: belting,
      reverse: true,
      filterValue: "Belting",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="divisionPage">
      <PageImgSection header={"Divisions"} imgs={imgs} />
      <DivisionSection />
      {divisionsData.map((division, i) => (
        <SubDivisionSection
          key={i}
          header={division.header}
          paragraph={division.paragraph}
          img={division.img}
          reverse={division.reverse}
          filterValue={division.filterValue}
        />
      ))}
    </main>
  );
};
export default DivisionPage;
