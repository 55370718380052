import React from "react";
// Stylesheet
import "./Layout.scss";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
//Images
const Layout = () => {
  return (
    <div className="">
      <NavigationBar />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
export default Layout;
