import React, { useState } from "react";
import { useContext } from "react";
import { ProductContext } from "../../store/product-store";
import "./SearchBar.scss";

import searchBarIcon from "./../../Images/searchBarIcon.svg";
import filterDropdown from "./../../Images/filterDropdown.svg";

const SearchBar = () => {
  const { filterHandler, searchHandler } = useContext(ProductContext);
  const [searchTerm, setSearchTerm] = useState("");
  // const [debouncingTimer, setDebouncingTimer] = useState(null);

  const filterOptionHandler = (e) => {
    filterHandler(e.target.dataset.type);
  };

  const searchTermHandler = (searchValue) => {
    setSearchTerm(searchValue);
    searchHandler(searchValue);
  };
  // useEffect(() => {
  //   if (debouncingTimer) {
  //     clearTimeout(debouncingTimer);
  //   }
  //   const newDebouncingTimer = setTimeout(() => {
  //     searchHandler(searchTerm);
  //   }, 500);

  //   setDebouncingTimer(newDebouncingTimer);

  //   return () => {
  //     if (newDebouncingTimer) {
  //       clearTimeout(newDebouncingTimer);
  //     }
  //   };
  // }, [searchTerm]);

  return (
    <div className="searchBarDiv">
      <div className="searchBarSubDiv">
        <input
          onChange={(e) => {
            searchTermHandler(e.target.value);
          }}
          placeholder="Enter Product Name..."
          className="searchBar"
          type="text"
          value={searchTerm}
        />
        <img className="searchBarIcon" src={searchBarIcon} alt="search" />
        <div className="filterDiv">
          <div className="filterButton">
            Filter
            <img
              className="filterDropdownIcon"
              src={filterDropdown}
              alt="filter"
            />
          </div>

          <ul onClick={filterOptionHandler} className="filterOptions">
            <li data-type="Ceramics" className="filterOption">
              Sort by ceramics
            </li>
            <li data-type="GasAndOil" className="filterOption">
              Sort by oil and gas
            </li>
            <li data-type="Belting" className="filterOption">
              Sort by belting
            </li>
            <li data-type="Conveyor" className="filterOption">
              Sort by Conveyor
            </li>
            <li data-type="Alphabet" className="filterOption">
              Sort by Alphabet
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SearchBar;
