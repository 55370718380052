import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Layout from "./Layout/Layout";
import Homepage from "./Pages/Homepage";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import DivisionPage from "./Pages/DivisionPage";
import ProductsPage from "./Pages/ProductsPage";
import Product from "./Pages/Product";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // errorElement:<ErrorPage/>,
    children: [
      { path: "/", element: <Homepage /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/divisions", element: <DivisionPage /> },
      { path: "/products", element: <ProductsPage /> },
      { path: "/products/:id", element: <Product /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
