import React from "react";
import "./DivisionCard.scss";
import PrimaryBtn from "./PrimaryBtn";
import { useNavigate } from "react-router-dom";

const DivisionCard = ({ img, header, text }) => {
  const navigate = useNavigate();

  const buttonHandler = () => {
    const route = `/divisions`;
    navigate(route);
  };

  return (
    <div className="divisionCard">
      <img className="cardImg" src={img} alt={header} />
      <h2 className="cardHeader">{header}</h2>
      <p className="cardText">{text}</p>
      <PrimaryBtn buttonHandler={buttonHandler} text={"Read More"} />
    </div>
  );
};
export default DivisionCard;
