import React from "react";
// Stylesheet
import "./DivisionSection.scss";
import Header from "../UI/Header";
//Images
const DivisionSection = () => {
  return (
    <section className="divisionSection">
      <Header text={"Divisions"} />
      <p className="divisionParagraph">
        NTB GROUP is highly diversified with USA & GERMAN collaborations, & 1
        Lac SQM modern state of the art works at CHAKAN, near Pune. We are
        market leaders in precision Hitech Industrial Ceramics / Carbons, Power
        Transmission / Conveyor Belts & Modular Conveyors / Automation systems.
        <br />
        <br />
        Since NTB was established, it has been supplying to almost all
        well-known companies, having presence in textiles, food, steel, tobacco,
        and engineering industries. The company has not only gained good
        reputation in domestic market but, also been exporting products to USA,
        Europe, UK, Africa and other countries.
        <br />
        <br />
        The company has been qualified with ISO 9001:2015 quality system. The
        production strictly complies with the standard and procedures of ISO
        9001, which ensure good product quality. Our advanced facilities, rich
        experience, efficient management and excellent sells team have helped to
        build network of 6000 + satisfied customers.
        <br />
        <br /> A win-win strategy with the vision in mind, which is "Satisfy
        customer with reasonable price, reliable quality & prompt service".
      </p>
    </section>
  );
};
export default DivisionSection;
