import mission from "./../Images/mission.svg";
import expertise from "./../Images/expertise.svg";
import sustainibility from "./../Images/sustainability.svg";
import quality from "./../Images/quality.svg";

export const overViewData = [
  {
    icon: mission,
    header: "Our Mission",
    paragraph:
      "We at NTB Hitech Ceramics are committed to the design and development, manufacture, and marketing of reliable, cost-effective, advanced Ceramic Components for industrial applications, to total customer satisfaction and meeting legal and statutory requirements related to the products.",
  },
  {
    icon: expertise,
    header: "Our Expertise",
    paragraph:
      "With over 30 years of experience, we excel in Technical Ceramics. Our cutting-edge facilities and skilled team ensure precision, quality, and on-time delivery, meeting industry standards.",
  },
  {
    icon: quality,
    header: "Quality Assurance",
    paragraph:
      "We maintain top-quality standards with rigorous inspections, international standard compliance, and continuous monitoring in our manufacturing. This ensures both reliability and regulatory compliance in our products.",
  },
  {
    icon: sustainibility,
    header: "Sustainability and Responsibility",
    paragraph:
      "NTB International prioritizes sustainability and responsible manufacturing. We employ eco-friendly tech, reduce energy usage, and emphasize waste reduction and recycling. Our corporate social responsibility extends to employees, communities, and stakeholders alike.",
  },
];

//industries data

//team data

//division data

//links

export const links = [
  {
    text: "Home",
    navigate: "/",
  },
  {
    text: "Division",
    navigate: "/divisions",
  },
  {
    text: "Products",
    navigate: "/products",
  },
  {
    text: "About us",
    navigate: "/about-us",
  },
  {
    text: "Contact us",
    navigate: "/contact-us",
  },
  // {
  //   text: "Resources",
  //   navigate: "/resources",
  // },
];
