import React from "react";
// Stylesheet
import "./Header.scss";
//Images
const Header = ({ text, small }) => {
  return (
    <div className="headerDiv">
      <h2 className={`header ${small && "footerHeader"}`}>{text}</h2>
    </div>
  );
};
export default Header;
